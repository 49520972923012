<template>
  <div class="container intake-forms-container">
    <div
      class="row page-header justify-content-between align-items-end mt-4 mb-5"
    >
      <div class="col">
        <h1 class="mb-0">Intake Forms</h1>
      </div>
      <div
        class="col text-right"
        v-if="user && (user.isAdmin || user.isManagingAdmin)"
      >
        <router-link
          :to="{ name: 'patients.intakeForms.create' }"
          class="btn btn-outline-blue mb-3 mb-lg-0"
        >
          <icon type="plus" /> Create New Intake
        </router-link>
      </div>
    </div>
    <alert v-if="intakeForms.isLoading" class="light-shadow" />
    <div
      class="
        card
        light-shadow
        padding-card
        mobile-tablet-no-card
        table-container
      "
      v-if="!intakeForms.isLoading && intakeForms.data.length > 0"
    >
      <table class="table table-striped mb-0 responsive-mobile-tablet-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Type</th>
            <th>Key</th>
            <th>Status</th>
            <th>Date Created</th>
            <th>Created By</th>
            <th>Date Completed</th>
            <th class="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="form in intakeForms.data" :key="form.id">
            <td
              @click="!form.loading && viewFormReport(form)"
              class="text-primary pointer-cursor"
            >
              <b class="mobile-tablet-only mr-2">ID:</b>{{ form.id }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Type:</b
              >{{ getFormType(form.type) }}
            </td>
            <td><b class="mobile-tablet-only mr-2">Mkey:</b>{{ form.mkey }}</td>
            <td>
              <b class="mobile-tablet-only mr-2">Status:</b
              ><span
                :class="`text-capitalize ${getStatusColor(form.status)}`"
                >{{
                  form.status == "consentDeclined"
                    ? "Consent Declined"
                    : form.status == "inProgress"
                    ? "In Progress"
                    : form.status
                }}</span
              >
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Date Created:</b
              >{{ form.created_at ? prettyDate(form.created_at) : "-" }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Created By:</b
              >{{ form.author || "-" }}
            </td>
            <td>
              <b class="mobile-tablet-only mr-2">Date Completed:</b
              >{{
                form.completed_at ? getDate(form.completed_at) : "Not Completed"
              }}
            </td>
            <td class="text-center td-actions">
              <button
                class="btn p-2 border-0 text-primary"
                @click="!form.loading && viewFormReport(form)"
              >
                <i class="fa fa-edit actionIcons" v-if="!form.loading"></i>
                <i
                  class="fa fa-spin fa-circle-notch mr-3"
                  v-if="form.loading"
                ></i>
                <b class="mr-2">View Report</b>
              </button>

              <button
                v-if="user && (user.isAdmin || user.isManagingAdmin)"
                class="btn p-2 border-0 text-black"
                @click="openIntakeModal(form)"
                title="more actions"
              >
                <i class="fa-solid fa-ellipsis-vertical actionIcons"></i>
                <b class="mr-2 mobile-only">More Actions</b>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <alert
      class="light-shadow"
      v-if="!intakeForms.isLoading && !intakeForms.data.length"
      :hideLoader="true"
      >No intake forms created yet.</alert
    >

    <modal
      class="intake-form-info-modal"
      name="intake-form-info-modal"
      transition="pop-out"
      width="96%"
      height="96%"
    >
      <div class="modal-container">
        <div
          class="modal-title border-bottom text-secondary position-relative p-3"
        >
          Intake Form Report
          <button class="close pr-2" @click="closeIntakeFormInfoModal">
            &times;
          </button>
        </div>
        <div class="modal-body">
          <div class="details-container card p-3">
            <intake-viewer
              :intake="selectedIntakeFormInfo"
              @change="updateSelectedIntakeFormInfo($event)"
            ></intake-viewer>
          </div>
        </div>
      </div>
    </modal>
    <modal
      class="intake-form-modal"
      name="intake-form-modal"
      transition="pop-out"
      :clickToClose="false"
      height="auto"
    >
      <div class="position-relative main-modal-container">
        <div class="vm--top-right-slot border-bottom pb-3" style="z-index: 1">
          <h2
            class="
              position-absolute
              m-3
              pt-1
              mr-5
              text-left
              font-weight-bold
              pointer-none
            "
            style="letter-spacing: 0.1px"
          >
            Intake Actions
          </h2>
          <button
            class="btn p-0 pr-3 pt-3 border-0"
            :disabled="intakeActionLoading"
            @click="closeIntakeModal"
          >
            <i class="fas fa-times fa-lg"></i>
          </button>
        </div>
        <div class="card-body mt-4">
          <div class="row border-bottom pb-4">
            <input-control
              v-model="(patientData.user || {}).phone_number"
              type="horizontal"
              labelClass="phone-label"
              wrapClass="align-items-center my-0"
              :cleave="cleave.phone"
              >Phone Number</input-control
            >
          </div>
          <div class="saving-note-container" v-if="intakeActionLoading">
            <div
              class="saving-note alert alert-info mb-1 mt-3 py-1"
              role="alert"
            >
              This may take few moments...
            </div>
          </div>

          <div class="mb-2 mt-5 d-flex justify-content-end">
            <save
              :shouldDisable="intakeActionLoading"
              iconType="envelope"
              @click="IntakeAction('email')"
              classes="btn btn-black mr-4"
              >Resend Email</save
            >
            <save
              :shouldDisable="intakeActionLoading"
              iconType="message"
              @click="IntakeAction('sms')"
              classes="btn btn-black mr-4"
              >Resend SMS</save
            >
            <save
              :shouldDisable="intakeActionLoading"
              iconType="copy"
              @click="IntakeAction('copy')"
              classes="btn btn-theme"
              >Get Intake URL</save
            >
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Swal from "sweetalert2";
import IntakeViewer from "./Viewer.vue";
import helpers from "@/utils/helpers";
import * as dayjs from "dayjs";

export default {
  name: "IntakeForms",
  components: { IntakeViewer },
  data() {
    return {
      selectedIntakeFormInfo: null,
      selectedIntakeToOpen: null,
      intakeActionLoading: false,
      cleave: {
        phone: { blocks: [20], numericOnly: true },
      },
    };
  },
  computed: {
    ...mapState(["intakeForms"]),
    ...mapState({
      user: (state) => state.auth.user,
      patient: (state) => state.patients.patient,
      companyName: (state) => (state.settings.companyProfile || {}).name || "",
      categories: (state) => state.intakeForms.categories.data,
    }),
    patientData: function () {
      return JSON.parse(JSON.stringify(this.patient));
    },
  },
  mounted() {
    this.getIntakeForms(this.patient.user_id);
    this.getIntakeCategories();
    this.listenToServerEvents();
  },
  watch: {
    "$route.query.form_id": {
      immediate: true, // Run on component load
      handler(newId) {
        if (newId) {
          this.viewQueryformReport(newId);
        }
      },
    },
  },
  destroyed() {
    window.Echo.leaveChannel(`intake-form-status`);
  },
  methods: {
    ...mapActions({
      getIntakeForms: "intakeForms/all",
      getIntakeReport: "intakeForms/getIntakeReport",
      sendIntakeNotification: "intakeForms/sendIntakeNotification",
      updateIntake: "intakeForms/update",
      getIntakeCategories: "intakeForms/getCategories",
    }),
    listenToServerEvents() {
      window.Echo.encryptedPrivate("intake-form-status").listen(
        "IntakeFormStatus",
        (event) => {
          if (event.environment_name != this.companyName) return;
          if (
            event?.data &&
            event.data.subject == "firsthx" &&
            event.data.id == this.patient.user_id
          ) {
            this.getIntakeForms(this.patient.user_id);
          }
        }
      );
    },
    getStatusColor(status) {
      if (status == "completed") {
        return "text-success";
      }
      if (status == "consentDeclined") {
        return "text-danger";
      }
      if (status == "created") {
        return "text-primary";
      }
      return "text-deep-warning";
    },
    getDate(formDate) {
      return dayjs(formDate)
        .add(dayjs(new Date(formDate)).utcOffset(), "minute")
        .format("YYYY-MM-DD (hh:mm A)");
    },
    prettyDate(formDate) {
      return helpers.prettyDate(formDate, "YYYY-MM-DD (hh:mm A)");
    },
    closeIntakeFormInfoModal() {
      this.$modal.hide("intake-form-info-modal");
      this.selectedIntakeFormInfo = null;
    },
    viewIntakeFormInfo(form) {
      const originform = JSON.parse(JSON.stringify(form));
      if (this.patient.diagnosis) {
        originform.report = {
          ...originform.report,
          Diagnosis: this.patient.diagnosis,
        };
      }
      if (this.patient.assessment_active) {
        originform.report = {
          ...originform.report,
          Assessment: this.patient.assessment,
        };
      }
      if (this.patient.treatment_active) {
        originform.report = {
          ...originform.report,
          Treatment: this.patient.treatment,
        };
      }
      if (this.patient.intervention_active) {
        originform.report = {
          ...originform.report,
          Intervention: this.patient.intervention,
        };
      }
      this.selectedIntakeFormInfo = originform;
      this.$modal.show("intake-form-info-modal");
    },
    updateSelectedIntakeFormInfo(data) {
      this.selectedIntakeFormInfo = data;
    },
    viewFormReport(form) {
      form.loading = true;
      this.$forceUpdate();
      this.getIntakeReport(form.id).then((data) => {
        form.loading = false;
        this.$forceUpdate();
        if (data.data) {
          this.viewIntakeFormInfo({ ...data.data, id: form.id });
        } else if (
          data.status !== "completed" &&
          data.status !== "consentDeclined"
        ) {
          this.handleInProgressForm(form.id);
        } else {
          this.viewIntakeFormInfo({ ...data, id: form.id });
        }
      });
    },
    viewQueryformReport(id) {
      this.getIntakeReport(id).then((data) => {
        if (data.data) {
          this.viewIntakeFormInfo({ ...data.data, id });
        } else if (
          data.status !== "completed" &&
          data.status !== "consentDeclined"
        ) {
          this.handleInProgressForm(id);
        } else {
          this.viewIntakeFormInfo({ ...data, id: id });
        }
      });
    },
    handleInProgressForm(id) {
      const vm = this;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "The intake form is not completed",
          text: "Do you want to complete it?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No, cancel",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            vm.$router.push({
              name: "patients.intakeForms.complete",
              params: { formId: id },
            });
          }
        });
    },
    navigateToForm(id) {
      this.$router.push({
        name: "patients.intakeForms.create",
        params: {
          formId: id,
        },
      });
    },
    getFormType(type) {
      const item = this.categories.filter((item) => item.type_id == type)[0];
      if (item) {
        return item.name;
      }
      return "-";
    },
    closeIntakeModal: function () {
      this.$modal.hide("intake-form-modal");
    },
    openIntakeModal: function (form) {
      this.selectedIntakeToOpen = form;
      this.$modal.show("intake-form-modal");
    },
    IntakeAction: async function (sendType) {
      const vm = this;
      if (sendType == "copy") {
        const fullLink = `https://${window.location.host}/intake/${vm.selectedIntakeToOpen?.mkey}`;
        return navigator.clipboard.writeText(fullLink).then(
          function () {
            Swal.fire({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              icon: "success",
              title: "The intake form link has been copied to your clipboard.",
            });
            vm.closeIntakeModal();
          },
          function (err) {
            console.error("Async: Could not copy text: ", err);
          }
        );
      }

      if (sendType == "sms" && !(vm.patientData.user || {}).phone_number) {
        return Swal.fire({
          title: "Error",
          text: "Please fill out the phone number",
          icon: "error",
        });
      }
      this.intakeActionLoading = true;
      try {
        const data = await vm.sendIntakeNotification({
          intakeId: vm.selectedIntakeToOpen.id,
          phoneNumber: vm.patientData.user.phone_number,
          sendType,
          domain: `${window.location.host}/intake`,
        });
        vm.intakeActionLoading = false;
        if (data) {
          vm.closeIntakeModal();
        }
      } catch (_) {
        vm.intakeActionLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.intake-form-info-modal {
  .v--modal {
    max-width: 96%;
  }
  .modal-title {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 26px;
    background-color: rgba(126, 126, 126, 0.1);
  }
  .close {
    color: black !important;
    font-size: 1.5em;
  }
  .modal-body {
    background-color: var(--body-bg-color);
    padding: 24px;
    height: calc(100% - 63px);
    overflow: auto;
    position: absolute;
    top: 63px;
    left: 0;
    right: 0;
  }
}
</style>

<style lang="scss">
.intake-form-modal {
  .main-modal-container {
    .phone-label {
      max-width: 150px;
      flex: 0 0 150px;
    }
  }
  .v--modal-box {
    overflow: visible;
    max-height: 100%;
    transition: none;

    @media (max-width: 768px) {
      max-height: 96vh;
      overflow: hidden;
      .card-body {
        max-height: calc(96vh - 47px);
        overflow-y: auto;
      }
    }
  }
}
</style>
